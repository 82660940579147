.fullArea {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.activeLabel {
  z-index: 1;
}

.labelContainer {
  &:hover {
    z-index: 1;
  }

  &:focus {
    outline: none;
  }
}

.infoCardContainer {
  z-index: 1;
}

.layersMenu {
  background: var(--colorWhite);
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 62px;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  max-width: 300px;
  box-shadow: var(--boxShadow);

  @media (max-width: 768px) {
    top: 50px;
  }

  & .mapLink {
    font-size: 14px;
    color: var(--colorGrey900);
    margin: 0;
    padding: 10px 12px;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .mapLinkIcon {
    width: 16px;
    margin-right: 6px;
  }

  & .mapLinkLabel {
    font-size: 14px;
    color: var(--colorGrey800);
  }

  & .mapLinkTitle {
    display: flex;
    align-items: center;
  }

  & .mapLinkSwitch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 12px;
    z-index: 0;
    border-radius: 100px;

    & .mapLinkSwitchInput {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      cursor: pointer;
    }

    & .mapLinkSwitchInput:checked + .mapLinkSwitchSlider {
      background-color: rgb(5 157 245 / 42%);
    }

    & .mapLinkSwitchInput:focus + .mapLinkSwitchSlider {
      box-shadow: 0 0 1px rgb(5 157 245 / 42%);
    }

    & .mapLinkSwitchInput:checked + .mapLinkSwitchSlider:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
      background-color: var(--marketplaceColor);
    }

    & .mapLinkSwitchSlider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 100px;

      &::before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: -3px;
        background-color: #fff;
        transition: 0.4s;
        border-radius: 100px;
        box-shadow: var(--boxShadowNotFoundPageSearch);
      }
    }
  }
}

.active {
  background-color: #3887be;
  color: #ffffff;
}

.mapOptions {
  background-color: var(--colorGrey100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;

  & .title {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: var(--colorGrey900);

    & > svg {
      margin-right: 5px;
    }
  }

  & .close {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: var(--colorWhite);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--colorGrey200);
    cursor: pointer;
    transition: var(--transitionStyle);

    &:hover {
      transition: var(--transitionStyle);
      background-color: var(--colorWhite);
      border: solid 1px var(--marketplaceColor);

      & > svg {
        transition: var(--transitionStyle);

        & path {
          transition: var(--transitionStyle);
          fill: var(--marketplaceColor);
        }
      }
    }

    & > svg {
      transition: var(--transitionStyle);
      width: 15px;
      height: 15px;

      & path {
        transition: var(--transitionStyle);
      }
    }
  }
}

.toggleLayer {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  color: var(--colorWhite);
  background: var(--colorWhite);
  border-radius: 50%;
  padding: 10px;

  @media (max-width: 767px) {
    top: 35px;
  }

  & img {
    width: 25px;
  }
}

.hideMenu {
  display: none;
}
